import React, { useContext, useState, useEffect, useCallback, useMemo } from "react"
import { Link } from "gatsby"

import GraphQLContext from '../context/GraphQLProvider';
import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/organisms/Seo/Seo"
import Button from '../components/atoms/Button/Button';
import Container from "../components/atoms/Container/Container"
import Divider from '../components/atoms/Divider/Divider';
import Filters from '../components/organisms/Filters/Filters';
import Icon from '../components/atoms/Icon/Icon';
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import ProductGrid from '../components/organisms/ProductGrid/ProductGrid'
import SearchForm from '../components/molecules/Search/Search'
import { getStorage } from '../helpers/general'

import ProcessSearch from '../components/molecules/Search/ProcessSearch.js'

import styles from './search.module.css'


const Search = () => {
    const [keyword, setKeyword] = useState('');
    const [results, setResults] = useState({items: [], totals: {count: 0}});
    const [qsChecked, setQSChecked] = useState(false);

    const graphQLContext = useContext(GraphQLContext);
    const [productInventories, setProductInventories] = useState([]);
    const [idsForInventories, setIdsForInventories] = useState([]);

    const [activeFilters, setActiveFilters] = useState({});
    const [currentFilters, setCurrentFilters] = useState({});

    const [filteredProduct, setFilteredProduct] = useState([]);
    const [relevantFilter, setRelevantFilter] = useState({});

    const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
    const [sortBy, setSortBy] = useState(urlParams.get('sortBy') && urlParams.get('sortBy') !== '' ? urlParams.get('sortBy') : getStorage('spencil_sortby'));
    const [loadingSortBy, setLoadingSortBy] = useState(false);
    
    const [eventIndex, setEventIndex] = useState(1);

    const [hideSidebar, setHideSidebar] = useState(false);

    const _hash = '#';

    const onFilterChange = (_filter) => {
        setEventIndex(eventIndex + 1);
        setActiveFilters({..._filter, cache: Math.random(10)})
    
        const _params = [];
        Object.keys(_filter).map(v => v.split('=')).map(v => {
            _params[v[0]] = typeof _params[v[0]] !== 'undefined' ? [..._params[v[0]], v[1]] : [v[1]]
            return v
        })
        const _query = Object.keys(_params).filter(k => k !== 'categories').map(k => encodeURIComponent(k) + '=' + encodeURIComponent(_params[k].join(','))).join('&');
        window.history.replaceState({}, ``, `${window.location.pathname}${_query !== '' ? `?${_query}` : ''}`)
    };

    /**
     * Check Inventory
     */
    const onInventoryCheck = useCallback((ids = []) => {
        const query = graphQLContext && graphQLContext.query;
        const idLength = ids.length;
        const chunk = 50;
        for (let i = 0; i < idLength; i += chunk) {
        const idChunk = ids.slice(i, i + chunk);

        query(
            `query($entityIds: [Int!] = [], $chunk: Int!) {
                site {
                products (first: $chunk, entityIds: $entityIds) {
                    edges {
                    node {
                        entityId
                        availabilityV2 {
                        status
                        }
                        inventory {
                        isInStock
                        aggregated {
                            availableToSell
                            warningLevel
                        }
                        }
                    }
                    }
                }
                }
            }`,
            { entityIds: idChunk, chunk: chunk }
        )
            .then(response => {
            if (response.data && response.data.site.products.edges.length > 0) {
                const _inventories = response.data.site.products.edges.map(b => {
                return {
                    entityId: b.node.entityId,
                    isInStock: b.node.inventory.isInStock,
                    stockLevel: b.node.inventory?.aggregated?.availableToSell,
                    warningLevel: b.node.inventory?.aggregated?.warningLevel,
                    availability: b.node.availabilityV2?.status,
                }
                });
                setProductInventories([...productInventories, ..._inventories]);
            }
            })
            .catch(error => {
            console.log("fetch list error:", error);
            });
        }
    }, [
        graphQLContext,
        productInventories,
    ]);

    /**
     * Inventory Memo
     */
    useMemo(() => {
        if (idsForInventories.length) {
            const _diffIds = idsForInventories.filter(a => productInventories.map(c => c.entityId).indexOf(a) < 0);
            if (_diffIds.length) {
                onInventoryCheck(_diffIds);
            }
        }
    }, [
        idsForInventories,
        productInventories,
        onInventoryCheck
    ]);

    const onSortBy = (e, param) => {
        e.preventDefault();
        if (!loadingSortBy) {
          setSortBy(param);
          setLoadingSortBy(true);
        }
    }

    useEffect(() => {
        if ((!qsChecked && typeof window !== 'undefined') || (eventIndex > 2 && qsChecked && JSON.stringify(currentFilters) !== JSON.stringify(activeFilters)) || loadingSortBy) {
            setQSChecked(true);
            setLoadingSortBy(false);
            const qs = window.location.search;
            const qsObj = new URLSearchParams(qs);
            const kw = qsObj.get('q');
            if (kw) {
                // console.log("SortBy: ", sortBy);
                setCurrentFilters(activeFilters);
                const _filters = [];
                Object.keys(activeFilters).map(a => {
                    if (typeof _filters[a.split('=')[0]] === 'undefined') {
                        _filters[a.split('=')[0]] = [a.split('=')[1]];
                    } else {
                        _filters[a.split('=')[0]].push(a.split('=')[1]);
                    }
                    return a;
                })

                if (typeof _filters['page'] !== 'undefined') {
                    delete _filters['page'];
                }

                if (typeof _filters['q'] !== 'undefined') {
                    delete _filters['q'];
                }

                if (typeof _filters['cache'] !== 'undefined') {
                    delete _filters['cache']
                }
                if (typeof _filters['sortBy'] !== 'undefined') {
                    delete _filters['sortBy']
                }

                ProcessSearch(kw, 1000, {

                    filters: Object.keys(_filters).filter(a => a !== '')
                                    .map((a) => `(${_filters[a].map((b) => `${a}:'${b}'`).join(' OR ')})`)
                                    .join(' AND '),
                    sortBy: sortBy
                }).then((results) => {
                    setKeyword(kw);
                    
                    const searchResults = {};
                    searchResults.items = results.items.map(dataSet => {
                        const modifier = {...dataSet};
                        if ('products' in modifier) {
                          modifier.products = modifier.products.filter(product => product.product_categories.length > 0 && ( product.product_categories[0].path.startsWith('/shop/') ));

                          if (modifier.allItems) {
                            modifier.allItems = modifier.allItems.filter(product => product.product_categories.length > 0 && ( product.product_categories[0].path.startsWith('/shop/')));
                          }
                          
                        }
              
                        return modifier;
                    });
                    
                    searchResults.totals = {};
                    searchResults.totals.count = 0;

                    if (searchResults.items[0].allItems) {
                        searchResults.totals.count = searchResults.items[0].allItems.length;
                    }
                    
                    setResults(searchResults)
                    setFilteredProduct(searchResults.items.length ? searchResults.items[0].allItems : []);

                    if (searchResults.items.length && searchResults.items[0].products.length) {
                        setIdsForInventories([...new Set([...idsForInventories, ...searchResults.items[0].products.map(b => b.entityId)])])
                    }

                    setTimeout(() => {
                        if (window) {
                          window.scroll(0, 0)
                        }
                      }, 400);

                    let filterObj = {
                        ages: [],
                        themes: [],
                        designs: [],
                        colors: [],
                    };
                    searchResults?.items[0]?.allItems?.map(k => {
                        filterObj.ages = [...new Set([...filterObj.ages, ...k.ages])];
                        filterObj.themes = [...new Set([...filterObj.themes, ...k.themes])];
                        filterObj.designs = [...new Set([...filterObj.designs, ...k.designs])];
                        filterObj.colors = [...new Set([...filterObj.colors, ...k.colors])];
                        return k
                    });

                    setRelevantFilter(filterObj);
                })
            } else {
                setResults([{error: true}]);
            }
        }
    }, [
        qsChecked,
        setQSChecked,
        setKeyword,
        setResults,
        eventIndex,
        activeFilters,
        currentFilters,
        loadingSortBy,
        idsForInventories,
        setLoadingSortBy,
        sortBy,
    ]);
    
    return (
        <Layout>
            <SEO title="Search" />
            <div className={styles.searchPageTitle}>
                {keyword && results.totals.count > 0  && (
                    <PageTitle title={`Showing ${results.totals.count} search results for`}>"{keyword}"</PageTitle>
                )}
                {!keyword && results.items && results.items.length === 0 && (
                    <PageTitle title={`Searching...`} />
                )}
                {keyword && results.totals.count === 0 && (
                    <PageTitle title={`No results found!`}>"{keyword}"</PageTitle>
                )}
                {!keyword && results.items && results.items.length > 0 && (
                    <PageTitle title={`Unable to search :(`} />
                )}

                {typeof results.items === 'undefined' && (
                    <PageTitle title={`Unable to search`}>invalid query</PageTitle>
                )}
                <Divider shape="teeth" />
            </div>
            <div className={styles.searchLayout}>
                <Container size="large">
                    {results.items && results.items.length > 0 && results.items.map((result, resultIndex) => {
                        // Add logic
                        if ('allItems' in result && result.allItems.length > 0) {
                            return <div className={`${styles.searchProductWrap} ${hideSidebar ? styles.hideSidebar : ''}`} key={resultIndex}>
                                <div 
                                    className={styles.sidebarOverlay} 
                                    onClick={() => { setHideSidebar(!hideSidebar) }} 
                                    onKeyPress={() => { setHideSidebar(!hideSidebar) }}
                                    role="presentation"></div>
                                <div className={styles.spwSidebar}>
                                    <Filters categories={[]} onChange={onFilterChange} relevantFilter={relevantFilter} />
                                </div>
                                <div className={styles.spwList}>
                                    <div className={styles.productFilterBar}>
                                        <div className={styles.hideFilter}>
                                            <Button target="a" link onClick={() => { setHideSidebar(!hideSidebar) }}>
                                            <span className={styles.desktop}>{hideSidebar ? 'Show Filters' : 'Hide Filters'}</span>
                                            <span className={styles.mobile}>{hideSidebar ? 'Hide Filters' : 'Show Filters'}</span>
                                            <Icon symbol="equaliser"/>
                                            </Button>
                                        </div>
                                        <div className={styles.sortProduct}>
                                            <Button target="a" link>
                                                Sort By <Icon symbol="arrowDown"/>
                                            </Button>
                                            <ul>
                                                <li className={sortBy === 'newest' ? styles.selectedSortBy : ''}>
                                                <a href={_hash} onClick={(e) => { onSortBy(e, 'newest') }}>Newest Items</a>
                                                </li>
                                                <li className={sortBy === 'A2Z' ? styles.selectedSortBy : ''}>
                                                <a href={_hash} onClick={(e) => { onSortBy(e, 'A2Z') }}>A to Z</a>
                                                </li>
                                                <li className={sortBy === 'Z2A' ? styles.selectedSortBy : ''}>
                                                <a href={_hash} onClick={(e) => { onSortBy(e, 'Z2A') }}>Z to A</a>
                                                </li>
                                                <li className={sortBy === 'PriceAsc' ? styles.selectedSortBy : ''}>
                                                <a href={_hash} onClick={(e) => { onSortBy(e, 'PriceAsc') }}>Price: Ascending</a>
                                                </li>
                                                <li className={sortBy === 'PriceDesc' ? styles.selectedSortBy : ''}>
                                                <a href={_hash} onClick={(e) => { onSortBy(e, 'PriceDesc') }}>Price: Descending</a>
                                                </li>
                                            </ul>
                                            {/* <select>
                                                <option value="featured" selected="">Featured Items</option>
                                                <option value="bestselling">Best Selling</option>
                                            </select> */}
                                        </div>
                                    </div>

                                    <ProductGrid products={filteredProduct} columns={3} />
                                </div>
                            </div>
                        }

                        if ('posts' in result && result.posts.length > 0) {
                            // TODO: Convert to component
                            return (
                                <div key={resultIndex}>
                                    {result.posts && (
                                        result.posts.map((post, postIndex) => (
                                            <a key={postIndex} href={post.url}>{post.title}</a>
                                        ))
                                    )}
                                </div>
                            )
                        }

                        if ('error' in result) {
                            return (
                                <div key={resultIndex}>
                                    Something went wrong, please <Link to="/">return home</Link>
                                </div>
                            )
                        }

                        return null
                    })}

                    {keyword && results.totals.count === 0 && (
                        <div className="flex-center">
                            <div className={styles.notFoundWrap}>
                                No results found for this search term. Please modify your search term below:
                                <br />
                                <br />
                                <div className="formField">
                                    <SearchForm toggleSuggestions={false} />
                                </div>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        </Layout>
    )
  }
  
  export default Search
  